import  { useEffect } from 'react';

type Props = {
    title: string; 
}

const useTitle = (props: Props) => {
    useEffect(() => {
        document.title = props.title;
        window.scrollTo(0, 0);
    }, [props.title]);
}

export default useTitle;
