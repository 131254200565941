import './ourbranches.css'
import { FaLocationDot } from 'react-icons/fa6'
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

const OurBranches = () => {
    const {t} = useTranslation()
    return (
        <>
            <div className="container my-5 px-5 px-md-0">
                <div className="section-intro text-center w-75 mx-auto  my-5">
                <div className="section-heading d-flex align-items-center justify-content-center gap-2 gap-md-5  ">
              <div className="h-line"></div>
              <h3 className='h3 display-6 text-nowrap'>{t('ourBranches.title')}</h3>
              <div className="h-line"></div>
            </div>
                </div>
                <div className="branches-cards">
                    <div className="branch-country  fs-2  ">{t('ourBranches.country')}</div>
                    <div className="row">
                        <div className="col-md-4 my-3 my-md-0">
                            <div className="branch-city ">{t('ourBranches.branch1.city')}</div>
                            <div className="locationContact">
                                <div className="location contact-info my-3">
                                    <div className="icon"><FaLocationDot /></div>
                                    <div className="adress">{t('ourBranches.branch1.location')}</div>
                                </div>
                                <div className="phone contact-info my-3">
                                    <div className="icon"><AiFillPhone /></div>
                                    <div className="adress">{t('ourBranches.branch1.phone')}</div>
                                </div>
                                <div className="email contact-info my-3">
                                    <div className="icon"><AiOutlineMail /></div>
                                    <a href='mailto:info@bluecascade.org' className="adress">{t('ourBranches.branch1.email')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 my-3 my-md-0">
                            <div className="branch-city ">{t('ourBranches.branch3.city')}</div>
                            <div className="locationContact">
                                <div className="location contact-info my-3">
                                    <div className="icon"><FaLocationDot /></div>
                                    <div className="adress">{t('ourBranches.branch3.location')}</div>
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="col-md-4 my-3 my-md-0">
                            <div className="branch-city ">{t('ourBranches.branch2.city')}</div>
                            <div className="locationContact">
                                <div className="location contact-info my-3">
                                    <div className="icon"><FaLocationDot /></div>
                                    <div className="adress">{t('ourBranches.branch2.location')}</div>
                                </div>
                                <div className="phone contact-info my-3">
                                    <div className="icon"><AiFillPhone /></div>
                                    <div className="adress">{t('ourBranches.branch2.phone')}</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurBranches