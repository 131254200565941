import { CircularProgress } from '@mui/material'
import React from 'react'

type Props = {}

const PageSkeleton = (props: Props) => {
    return (
        <>

        <div className="loading " style={{
            width:'100vw',
            height:'100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems:'center',
            color:'white',
            fontSize:'40px',
        }}
        >
             <CircularProgress disableShrink />
        </div>

        </>
    )
}

export default PageSkeleton