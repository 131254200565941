import { useTranslation } from "react-i18next"
const ContactGetInTouch = () => {
  const { t } = useTranslation()
  return (
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-lg-6 col-md-10 mx-auto">
            <img className='w-100  img-fluid' src="../assets/images/get-in-touch-image-1.webp" alt="" />
          </div>
          <div className="col-lg-6 col-md-10 mx-auto px-5 mt-5 mt-md-5 mt-lg-0">
            <div className="details-about w-100 h-100 px-3">
              <div className=" h1 fw-bold display-4 ">{t('contact.title')}</div>
              <div className="touchSubHeading h3 fw-bold my-4">{t('contact.subtitle')}</div>
              <div className="p detail px-1">
                {t('contact.description')}              </div>
              <div className="mission-qualities d-flex gap-3 pt-5">
                <div className="quality p">{t('contact.hours')}</div>                
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default ContactGetInTouch