import { useEffect, useState } from "react";
import './scrolltop.css'
// import { Button } from "react-bootstrap";

const ScrollTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const goToBtn = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const listenToScroll = () => {
        let heightToHidden = 20;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHidden) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    return (
        <>
            {isVisible && (
                <button  id="toTop" style={{display:'inline'}} onClick={goToBtn}>
                    <span id="toTopHover" style={{opacity:'0'}}></span>
                    To Top
                </button>
            )}
        </>

    );
};



export default ScrollTop;