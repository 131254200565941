const eventData = [
    {
        "id": 1,
        "date": "14-Feb-2023",
        "title": "Cricket Tournament",
        "description": "Blue Cascade has a history of introducing healthy cultures among its employees and keeping the tradition alive, the first-ever mega Cricket Tournament was organized by the Founder at DHA sports complex on the 11th of February 2023 so that the staff can get a break from their exhausting routine and spend a nice time together and make memories. The executives at the company believe that sports are a great way to energize employees, teach teamwork in an unconventional way, and avoid burnout at work.All the participants left the field happier than they came and took back great memories with them. It was also a great way for Cascadians from different departments to hang out and get to know each other. In two words, the event was TOP-NOTCH!",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/Cricket-Tournament.jpeg",
        "category": "Company News"
    },
    {
        "id": 2,
        "date": "10-Nov-2022",
        "title": "Business Conference 2.0",
        "description": "This year Blue Cascade was able to hold a business conference. It was the first time for Blue Cascade to go public and share.The purpose was to make people think out of the box and devise new solution towards manufacturing their own products.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/business_conference.webp",
        "sub_image": "../assets/images/events/business_conference.webp",
        "category": "Company News"
    },
    {
        "id": 3,
        "date": "10-Nov-2022",
        "title": "Culture",
        "description": "The culture of Blue Cascade is employee friendly. Different sessions are held to make the spirits of Cascadians sky-high. Blue Cascade has always been of the thought of breaking the illusions in every respect specially breaking the stereotypes about the environment of an organization by giving the free atmosphere to everyone to openly speak up about their perspectives. Every session is based on different topics for discussion. The core purpose of every session is to focus on the personal growth of every individual.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/culture.webp",
        "sub_image": "../assets/images/events/behavioral_Growth.webp",
        "category": "Company News"
    },
    {
        "id": 4,
        "date": "10-Nov-2022",
        "title": "Informal Gatherings",
        "description": "Holding social events is necessary as they go a long way towards reducing tensions in the office environment and it results in driving out more positive outcomes from the workers. All Cascadians are like a family celebrating the success of every single person together. Sharing an equal level of importance in the work environment stands out Blue Cascade from others. The executives are always looking for new avenues and ventures to bring all the employees together.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/Informal-Gatherings.webp",
        "sub_image": "../assets/images/events/Informal-Gatherings-and-Trips-1-1.webp",
        "sub_image_line": "It has always been the vision of Blue Cascade to break the illusion of going with the same trends and adapting versatile trends to bring up the better version of the company itself. These discourses are held to eradicate reluctancy of sharing thoughts between the employees and executives and to encourage productive collaboration. We offer trips as well for our employees on a yearly basis to develop a vibrant culture and inspiring employees.",
        "sub_image1": "../assets/images/events/Informal-Gatherings-and-Trips001-1.webp",
        "sub_image1_line": "We offer trips as well for our employees on a yearly basis to develop a vibrant culture and inspiring employees.",
        "sub_image2": "../assets/images/events/Informal-Gatherings-and-Trips01.webp",
        "category": "Company News"
    },
    {
        "id": 5,
        "date": "08-Nov-2022",
        "title": "Ways To Hit Definite Success, Selling Via The 3 Business Models Of Amazon",
        "description": "Ihave run into people asking me frequently about how to perfectly conduct business over Amazon keeping to good revenue. I have been into this business for quite a while now for which I even boast to rack up to 80k of pure turnover, but trust me, even I cannot present the ideal selling criteria. But on the other hand, guess what, you need to bring yourself closer to the ideal, in case you cannot reach it. So, here goes.\nThe thing is, with whatever guide you come across, thorough research is the best way to go. A good guide will only show you the way or leave you the hassle to pick out where exactly to start from. The experience that I would like to share goes a long way than being just a guide.\nSelling over Amazon is no easy task and as I would like to call it, “straight anxiety”, it certainly is exactly that. But sticking to Amazon for quite a while, I have picked out some minute things or you may call them as “hacks” that can leave you the pre-maturation anxiety. Either I do have a way with words or Amazon has grown over me, big time. But trust me, it’s still worth it as the dust settles.\nWell, this brings us to the 3 business models that Amazon operates under. Actually, people start as rookies from one business model which is in most cases FBM. They then jump off to FBA having gathered a thorough knowledge of the basic Amazon policies that are a must to be observed. With enough luck and in case some voodoo trick works out just fine, the lucky folks are able to land on to Private Labeling or simply PL.\nBy now, many of you will be already scratching their heads begging to end the chit chat and get down to business. Well, hold onto your horses while I pen down some key points to loosen up the talk. Low-key vendors are already there to accompany you when you think of selling over Amazon.\nProvide the correct information and get yourself registered with Amazon seller program.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/event-amazon.webp",
        "category": "Company News"
    },
    {
        "id": 6,
        "date": "18-Jun-2015",
        "title": "Annual Dinner 2.0",
        "description": "Annual Dinner 2.0Franklin D. Roosevelt rightly said, “A smooth sea never made a skilled sailor.” Though we got onto experience the same, not even opting it for ourselves. The happy faces on this page better describe it was yet again win-win situation for the Cascadians, but it all came embedded with a catch-22. Asking each of us to knock down the tough times with resilience and putting in the better versions of ourselves on the show.\nThe abstract idea of 2.0 was on the quest for us at Cascade and we got onto hunt it through this year to stand on the victorious side. Triumphs already on the side and the art of changing the movement of the tides, this outing was about getting steadier, calmer and focused.\nThis event marked 2nd anniversary of Blue Cascade, which was actually birth of an idea and the pledge to ‘break the illusions’.\nThe prodigies joining Blue Cascade had a great run this year and this was the time of the year when they should be cherished and appreciated for their blood, sweat and tears for Blue Cascade. Taking the road not meant to be taken by many doesn’t make it formidable, it just takes you one step closer to where you belong, success.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/annual_dinner_2.0.webp",
        "sub_image": "../assets/images/events/boss.webp",
        "sub_image1": "../assets/images/events/boss.webp",
        "sub_image2": "../assets/images/events/iii-saints-1-300x200.webp",
        "sub_image3": "../assets/images/events/iii-smurfs-300x200.webp",
        "sub_image_line":"\nSome Highlights from Blue Cascade Annual Dinner 2.0\nCarrying on with the custom, parents and families were specially invited as they stand as an integral part of Blue Cascade Family. The former Cascadians were also invited at this glitzy event that had some rocking performances from the doers in the team.\nJust to get you familiar with how things go at Blue Cascade, we have established three teams/houses on the basis of hierarchy.  Here is how it goes:\ni) Friends\nii) Saints\niii) Smurfs\nEach of the team made it to the stage with their signature moves and anthems, which absolutely got the crowd on their feet. In order to keep on with the elegance and decency, we had some superb speakers on the eve as well. Just to settle out your curiosity, amid the list of speakers we had our beloved Founder Salman Altaf and Co-founder Farhan Khalid enlightening the attendees.\nAt the same time, the humors character sketches made attendees burst out laughing and what doubled the fun was a comical ‘News Headlines’ brought up by the Smurfs.  The most awaited sight was the prize distribution as it was time to laud the standout performers of the year 2018-19. What followed next was a scrumptious dinner with a variety of cuisines and salads to tantalize the taste buds of attendees.",
        "category": "Company News"
    },
    {
        "id": 7,
        "date": "18-Jun-2015",
        "title": "Annual Dinner3.0",
        "description": "BlueCascade, along with its greatly escalating exposure and ventures alike, bears a sturdy faith that as goes work load and drudgery, there must also be ample space for other recreational activities. BlueCascade provides the space and the time to bask and relax which is key given the stress the team is able to handle during the work hours and beyond. BlueCascade arranged an annual dinner which reflected the strong basis of the company which has now melted into being a fortified team. The dinner unlike any dinner for a corporate structure like the one BlueCascade has turned out to be, was majorly an array of simplistic amiable aura which majorly focused over the roots from which BlueCascade emerged out of. The founder of BlueCascade, Salman Altaf pondered over how the genesis version of the company had very few members of the team but gave their all for the company. The co-founder, Farhan Khalid and the chief executive officer BlueCascade, Umair Asghar Gurchani were celebrated owing to their services for the company and the strongest of wills which kept them supporting the founder as he explored and dominated every department and avenue he turned his course to. Each member of the team was drawn to light as they elaborated over their experience with the company so far and what they expect out of the company that can wholly and legitimately reflect their services.\nWith promising dreams and passion, the team officially stepped foot over the corporate ladder, making and carving their own route towards success as this is what BlueCascade has been renowned for. The conclusion of the event was crucially focused over the pointing out the goals, vision that the founder had as BlueCascade was given form, bit by bit and empowering the local man to stand out to the world.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/annual_dinner_3.0.webp",
        "category": "Company News"
    },
    {
        "id": 8,
        "date": "18-Jun-2015",
        "title": "Behavioural Growth Discourses or Sessions",
        "description": "Breaking the illusions’ is never the easy part and BlueCascade has grasped the fact already accepting that they need to be the breaker of the chains that have kept the stereotypes intact for a prolonged period of time. Spreading out of these stereotypes is what the true spirit of the Cascadians, sky high as they always manage to stand upright after the dust settles. Sticking to the vision originally brought to existence, by the chief executive Officer, Umair Asghar Gurchani, Cascadians vow to bring the power not only to their person and the team but to each and every man that they interact with bringing out of the shadows of individual constraints.\nBlueCascade seeks to preach this vision and adopt measure to, at least empower the entire team associated with BlueCascade. Each individual can in turn, act as the preacher him/herself encouraging a local man to jump out of the illusion paradox. The careful measures can bring the due knowledge to specific and can act as the straw whenever he/she goes down drowning. BlueCascade regularly arranges sessions and meetups, setting aside the usual business and corporate debates and talks, focusing over the personal growth which can shape up individuality.  The sessions allow each member of the team the liberty to speak his/her heart out brining light to minor yet decisive topics that must be catered for on expedited basis. The sessions usually focus over a major topic as the member sit in an intellectual circle presenting their side of the story and offer opinions. The sessions terminate as each member decides to improve health parameters and scaling his/her personal development.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/behavioral_Growth.webp",
        "category": "Company News"
    },
    {
        "id": 9,
        "date": "18-Jun-2015",
        "title": "ORGANIZING AMIABLE EVENTS",
        "description": "A work load if not performed willingly turns to an unpleasant drudgery which must be cleansed out of the team in order to promote smooth and outcome-based tasks. BlueCascade, though still spreading its wings delving and molding into a corporate structure with every department being re-branded and being served with a viable decently operating framework, the team requires to cool off the steam after executing the operations to perfection on a daily basis. Perfection is a crucial part of all the processes and human body is no exception. BlueCascade ensures that the team enjoys a sound hygiene planning sports galas and hangouts that jot down a special place for BlueCascade in the memory lane for each member of the team.\nBlueCascade produces the most stress-free workplace inducing the sense of empowerment within each of the team members, without any indifference. With outcome-based tasks assigned to the teams within BlueCascade, it is much easier to manage the work load and set apart time for these outside sports activities. The motive for these games beside being the usual bookworm-ish and purely technical, is to bound the team together inducing a sense of sportsmanship which gives way to the team spirit that comes in handy promoting efficiency of the team altogether. Curating the team into a sturdy bond switches the spirits up high as each member of the team not only empowers his/her personal self but exerts force to propel BlueCascade as a whole.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/amiable_events.webp",
        "category": "Company News"
    },
    {
        "id": 10,
        "date": "18-Jun-2015",
        "title": "Formal Gatherings",
        "description": "Besides promoting the casual cum corporate face of Blue Cascade, Blue Cascade pull off arranging and managing formal meetups elegantly and quite exquisitely. Notifying general announcements are a feature of a daily notifications, the authentic announcements are housed by formal gathering and meetups that attendees of which base over the sort of discourse or the discussions the team is to conduct. Blue Cascade recently announced the appointment of the new chief executive officer, Umair Asghar Gurchani. Umair previously served Blue Cascade as the Managing Director, being among the one who gave the corporate visage to Blue Cascade. The announcement featuring a formal meetup, was set up decently and organized well.\nBlue Cascade is always on the lookout to exploring further avenues and ventures leaving a mark of sheer domination wherever it set its foot on. The executive team conducts sessions and meetups to discussing the future aspects and the benefits that stand out other than merely financial. These discourses usually take place in an official setting where every member gets to have his say spoken out loud with positive feedbacks extracted out to further empower the company. Breaking the usual abominable trends has always been the vision for Blue Cascade which is refined with time and nurtured with the best and the most diligent team that a corporate industry can wish for.",
        "author": "usmanaltaf1",
        "comments": 0,
        "image": "../assets/images/events/formal_gathering.webp",
        "category": "Company News"
    }
];

export default eventData;
