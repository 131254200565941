// App.js

import React, { Suspense, useState } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import ContactPage from './Pages/ContactPage';
import BlogPost from './components/Blog/BlogPost';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
// import AboutPage from './Pages/AboutPage';
// import EventsPage from './Pages/EventsPage';
// import CareerPage from './Pages/CareerPage';
import ScrollTop from './components/scrollToTop/ScrollTo';
import EventDetail from './components/EventDetail/EventDetail';
import PageSkeleton from './components/Reusable/PageLoading';

const HomePage = React.lazy(() => import('./Pages/HomePage'))
const AboutPage = React.lazy(()=>import('./Pages/AboutPage'))
const CareerPage = React.lazy(()=>import('./Pages/CareerPage'))
const EventsPage = React.lazy(()=>import('./Pages/EventsPage'))
const TeamPage = React.lazy(()=>import('./Pages/TeamPage'))




function App() {
  const location = useLocation();


  return (
    <>
      <Suspense fallback={<PageSkeleton/>}>
      {!location.pathname.startsWith('/admin') && !location.pathname.startsWith('/login') && <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog/single" element={<BlogPost />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/event/:title" element={<EventDetail />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/team" element={<TeamPage />} />
          
        </Routes>
      {!location.pathname.startsWith('/admin') && !location.pathname.startsWith('/login') && <ScrollTop />}
      {!location.pathname.startsWith('/admin') && !location.pathname.startsWith('/login') && <Footer />}
      </Suspense>
    </>
  );
}

export default App;
