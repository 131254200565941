import { useEffect } from "react";
import ContactGetInTouch from "../components/contactTouch/ContactGetInTouch";
import OurBranches from "../components/ourBranches/OurBranches";
import useTitle from "../hooks/useTitle";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const ContactPage = () => {
  const {t} = useTranslation()
  useTitle({ title: 'Contact Us - Bluecascade' });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact-banner banner">
        <div className="overlay">
          <div className="banner-details container">
            <div className="banner-title h1  text-white order-1 order-md-0">{t('pages.contact')}</div>
            <div className="breadcrumb text-white d-flex gap-2 order-0 order-md-1"><Link to={'/'}>{t('pages.home')}</Link> / <Link to={'/contact'}>{t('pages.contact')}</Link></div>          </div>
        </div>
      </div>
      <ContactGetInTouch />
      <OurBranches/>
    </>
  )
}

export default ContactPage