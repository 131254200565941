import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Header.css'
import { FaFacebookF } from 'react-icons/fa6'
import { BiLogoLinkedin } from 'react-icons/bi'
import { ImMenu } from 'react-icons/im'
import { useTranslation } from 'react-i18next'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import React from 'react'
const Header = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAbout = () => setShowAbout(false);
  const handleShowAbout = () => setShowAbout(true);


  const [scrollY, setScrollY] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isScrolled = scrollY > 50;
  const isLargeScreen = window.innerWidth > 768;
  return (
    <div className={`container-fluid main-navbar  ${isScrolled && isLargeScreen ? 'fixed' : ''}`}>
      <div className=' position-relative container'>
        <div className="container">
          <div className={`topbar ${isScrolled && isLargeScreen ? 'hide' : ''} text-light d-flex justify-content-between  align-items-center flex-column flex-md-row pt-5 py-md-2  px-2`}>
            <div className="contact-into d-flex gap-4 mb-3 mb-md-0">
              <a className='text-nowrap' href="tel:+0616520508">{t('header.number')}</a>
              <a href="mailto:info@bluecascascade.org">{t('header.email')}</a>
            </div>
            <div className="social-links d-flex gap-3  ">
              <a href="https://www.facebook.com/blucascascade"><FaFacebookF /></a>
              <a href="https://www.linkedin.com/company/blue-cascade/mycompany/"><BiLogoLinkedin /></a>
            </div>
          </div>
          <hr className={`text-white header__line pb-2 text-center  mx-auto ${isScrolled && isLargeScreen ? 'hide' : ''}`} />
          <div className="navbar nav__bar py-0  py-md-3">
            <div className="logo">
              <Link to={'/'}><img src={'../assets/images/bluecascade-1.webp'} alt="Bluecascade" /></Link>
            </div>
            <div className="navlinks">
              <div className="links d-flex gap-lg-2 gap-2 ">
                <Link className={`nav__link ${location.pathname === '/' ? 'active' : ''}`} to='/'>{t('header.links.home')}</Link>
                <Link className={`nav__link about ${location.pathname === '/about' ? 'active' : ''}`} to='/about'>
                  {t('header.links.about.name')}
                  <div className="about-child d-flex flex-column text-capitalize">
                    <Link to={'/about'} className="about-us-links ab">{t('header.links.about.child1')}</Link>
                    <Link to={'/team'} className="about-us-links tb">{t('header.links.about.child2')}</Link>
                  </div>
                </Link>
                <Link className={`nav__link ${location.pathname === '/career' ? 'active' : ''}`} to='/career'>{t('header.links.career')}</Link>
                <Link className={`nav__link ${location.pathname === '/events' ? 'active' : ''}`} to='/events'>{t('header.links.events')}</Link>
                <Link className={`nav__link ${location.pathname === '/contact' ? 'active' : ''}`} to='/contact'>{t('header.links.contact')}</Link>
              </div>
            </div>

            <div className="mobile-menu">
              <button className="menuIcon " onClick={handleShow}><ImMenu />Menu</button>
            </div>

            <Offcanvas placement='end' show={show} onHide={handleClose} style={{ width: '70%' }}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title> </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="mobile___links d-flex flex-column">
                  <hr />
                  <Link to='/' onClick={handleClose} className="mobile__link ">Home</Link>
                  <hr />
                  <div style={{ cursor: 'pointer' }} onClick={handleShowAbout} className="mobile__link d-flex justify-content-between">
                    <div className="aboutMobile" >About</div>
                    <div className="about__arrow fs-6"> <MdOutlineArrowForwardIos /> </div>
                  </div>
                  <hr />
                  <Link to='/career' onClick={handleClose} className="mobile__link">Careers</Link>
                  <hr />
                  <Link to='/events' onClick={handleClose} className="mobile__link">Events</Link>
                  <hr />
                  <Link to='/contact' onClick={handleClose} className="mobile__link">Contact</Link>
                  <hr />
                </div>
              </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas placement='end' show={showAbout} onHide={handleCloseAbout} style={{ width: '70%' }}>
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="mobile___links d-flex flex-column">
                  <div onClick={handleCloseAbout} className="disabled py-2">About Us</div>
                  <hr />
                  <Link to='/about' onClick={() => { handleClose(); handleCloseAbout(); }} className="mobile__link">About</Link>
                  <hr />
                  <Link to='/team' onClick={() => { handleClose(); handleCloseAbout(); }} className="mobile__link">Our Team</Link>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header