import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import eventData from '../../data/events';
import useTitle from '../../hooks/useTitle';
import './eventdetail.css'
import { BsFillFilePersonFill } from 'react-icons/bs'
import { MdDateRange } from 'react-icons/md'
import { BiComment } from 'react-icons/bi'

const EventDetail = () => {
  const { title } = useParams<{ title: string }>();
  const event = eventData.find((e) => e.title === title);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [event]);
  if (!event) {
    return <div>Event not found</div>;
  }
  const uniqueYears: any = [];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useTitle({ title: `${event.title} - Bluecascade` });

  // style for inputs in the comment section
  const commentInputStyle = {
    borderRadius: 'none',
    boxShadow: 'none',
    ':active': {
      boxShadow: 'none'
    },
    '::before': {
      boxShadow: 'none'
    },
    '::after': {
      boxShadow: 'none'
    },
    // Add other styles as needed
  };

  return (
    <>
      <div className="event-detail-wrapper">
        <div className="event-detail-banner banner">
          <div className="overlay">
            <div className="banner-details container">
              <div className="banner-title h2 text-center  text-white order-1 order-md-0">{event.title.split(' ').slice(0, 5).join(' ')}</div>
              <div className="breadcrumb text-white d-flex  gap-2 order-0 order-md-1 "><Link to={'/'}>Home</Link> / <Link to={'/events'}>{event.category}</Link> / <Link to={`/event/${event.title}`}>{event.title.split(' ').slice(0, 4).join(' ')}</Link></div>
            </div>
          </div>
        </div>
        <div className="event-container container my-5">
          <div className="row">
            <div className="col-md-3 p-3 order-1 order-md-0">
              <div className="recentPosts h6">Recent Posts</div>
              <div className="events-name my-4">
                {
                  eventData.slice(0, 5).map((event) => (

                    <div className=" d-flex  event">
                      <span className='dash'>-</span>
                      <Link to={`/event/${event.title}`} className='mx-3 eventName' >{event.title}</Link>
                    </div>
                  ))
                }
              </div>
              <div className="recentPosts h6 mt-5">Archives</div>
              <div className="events-name my-4">


                {
                  eventData.map((event, index) => {
                    const year = event.date.split('-')[2];

                    // Check if the year is not in the uniqueYears array
                    if (!uniqueYears.includes(year)) {
                      // Add the year to the uniqueYears array
                      uniqueYears.push(year);

                      return (
                        <div className="d-flex event" key={event.id}>
                          <span className='dash'>-</span>
                          <Link to={`/event/${event.title}`} className='mx-3 eventName'>{`Year ${year}`}</Link>
                        </div>
                      );
                    }

                    return null; // Skip mapping if it's not the first date of the year
                  })
                }


              </div>
              <div className="recentPosts h6 mt-5">Category</div>
              <div className="events-name my-4">

                {eventData.slice(0, 1).map((event) => (
                  <div className="d-flex event" >
                    <span className='dash'>-</span>
                    <Link to={''} className='mx-3 eventName'>{event.category}</Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-9 order-0 order-md-1">
              <div className="event-detail-picture">
                <img src={event.image} alt={event.title} />
              </div>
              <div className="category-name my-3">{event.category}</div>
              <div className="event-detail-title display-5 h6 fw-bold">{event.title}</div>
              <div className="event-reaction d-flex text-lowercase gap-4 my-3">
                <div className="event-post-details d-flex align-items-center">
                  <div className="post-icon "><BsFillFilePersonFill /></div>
                  <div className="post-author-name">{event.author}</div>
                </div>
                <div className="event-post-details d-flex align-items-center">
                  <div className="post-icon "><MdDateRange /></div>
                  <div className="post-author-name text-uppercase">{event.date}</div>
                </div>
                <div className="event-post-details d-flex align-items-center">
                  <div className="post-icon "><BiComment /></div>
                  <div className="post-author-name">{event.comments} comments</div>
                </div>
              </div>
              <div className="event-detail-description p">
                {event.description}
              </div>

              {event.sub_image && (
                <div className="event-detail-picture my-5">
                  <img src={event.sub_image} alt="" />
                </div>
              )}
              {event.sub_image_line && (
                <div className="event-detail-description p">
                  {event.sub_image_line}
                </div>
              )}

              {event.sub_image1 && !event.sub_image3 && (
                <div className="event-detail-picture my-5">
                  <img src={event.sub_image1} alt="" />
                </div>
              )}

              {event.sub_image1_line && (
                <div className="event-detail-description p">
                  {event.sub_image1_line}
                </div>
              )}

              {event.sub_image2 && !event.sub_image3 && (
                <div className="event-detail-picture my-5">
                  <img src={event.sub_image2} alt="" />
                </div>
              )}
              {
                event.sub_image2 && event.sub_image3 && (
                  <div className="sub_images-container mt-5 mx-auto">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <img className='w-100 ' src={event.sub_image1} alt="" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <img className='w-100 ' src={event.sub_image2} alt="" />
                      </div>
                      <div className="col-md-4 mb-3">
                        <img className='w-100 ' src={event.sub_image3} alt="" />

                      </div>
                    </div>
                  </div>
                )
              }





              <div className="leave-comment mt-5 py-5">
                <div className="h4 fw-bold my-2">Leave a Comment</div>
                <form action="">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <textarea style={commentInputStyle} className="form-control commentI" placeholder="Leave a comment here" rows={5} id="floatingTextarea"></textarea>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-12 ">
                          <input style={commentInputStyle} type="text" className="form-control commentI py-3 mb-3" id="floatingInputInvalid" placeholder="Name" />
                        </div>
                        <div className="col-12 ">
                          <input style={commentInputStyle} type="email" className="form-control commentI py-3 " id="floatingInputInvalid" placeholder="Email" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="btn commentBtn mt-4" style={{ backgroundColor: ' #00415C', color: 'white' }}>Comment</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetail;
